import React from 'react';
import PropTypes from 'prop-types';

import styles from './pillars.scss';

const PillarsColumn = ({
    children,
}) => (
    <div className={styles.pillar}>
        {children}
    </div>
);
PillarsColumn.propTypes = {
    // The pillar column content
    children: PropTypes.node.isRequired,
};

export default PillarsColumn;
