import React from 'react';
import PropTypes from 'prop-types';

import styles from './block.scss';

const BlockColumn = ({
    children,
}) => (
    <div className={styles.block__column}>
        {children}
    </div>
);
BlockColumn.propTypes = {
    // The column content
    children: PropTypes.node.isRequired,
};

export default BlockColumn;
