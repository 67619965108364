/**
 * Containers / Page
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Header from '../../organisms/Header';
import Footer from '../../organisms/Footer';

import styles from './page.scss';

const PageContainer = ({
    label,
    title,
    children,
}) => (
    <article
        id={`page-${label}`}
        className={styles.page}
    >
        <Helmet titleTemplate="%s &mdash; Bohlbro.dk">
            <title>{title}</title>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content={`${title} page`} />
        </Helmet>
        <Header />
        <main id="main" className={styles.main} role="main">
            {children}
        </main>
        <Footer />
    </article>
);
PageContainer.propTypes = {
    /* The id label parsed to the article element */
    label: PropTypes.string.isRequired,

    /* The title string used as the display title for the page */
    title: PropTypes.string.isRequired,

    /* The default children */
    children: PropTypes.node.isRequired,
};

export default PageContainer;
