/**
 * Pages / NotFound
 */

import React from 'react';
import PropTypes from 'prop-types';

import PageTemplate from '../../templates/Page';
import Hero from '../../organisms/Hero';

/**
 * The default NotFound page shown at unkown urls
 */
const NotFoundPage = ({
    staticContext,
}) => {
    staticContext.status = 404; // eslint-disable-line

    return (
        <PageTemplate label="not-found" title="404 Not found">
            <Hero>
                <Hero.Header
                    title="404, Page not found."
                    subtitle="The page you requested doesn't exist, sorry!"
                />
            </Hero>
        </PageTemplate>
    );
};
NotFoundPage.propTypes = {
    /* The context provided by the server */
    staticContext: PropTypes.object, // eslint-disable-line
};
NotFoundPage.defaultProps = {
    staticContext: {},
};

export default NotFoundPage;
