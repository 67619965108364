import React from 'react';

import Button from '../../atoms/Button';
import Menu from '../../molecules/Menu';

import FooterColumn from './FooterColumn';

import styles from './footer.scss';

const primaryMenu = [
    {
        to: '/',
        label: 'Home',
    },
    /*
    {
        to: '/work',
        label: 'Work',
    },
    */
    {
        to: '/about',
        label: 'About',
    },
    {
        to: '/contact',
        label: 'Contact',
    },
];

const secondaryMenu = [
    /*
    {
        href: 'https://play.bohlbro.dk',
        label: 'Playground',
    },
    */
    {
        href: 'https://dribbble.com/Phoenix1355',
        label: 'Dribbble',
    },
    {
        href: 'https://www.linkedin.com/in/phoenix1355/',
        label: 'Linkedin',
    },
    {
        href: 'https://github.com/Phoenix1355',
        label: 'Github',
    },
    {
        href: 'https://bohlbro.dk/resume.pdf',
        label: 'Resume',
    },
    /*
    {
        to: '/blog',
        label: 'Blog',
    },
    */
];

const copyrightMenu = [
    {
        to: '/privacy',
        label: 'Privacy',
    },
    /*
    {
        to: '/cookies',
        label: 'Cookies',
    },
    */
];

const Footer = () => (
    <footer className={styles.footer}>
        <div className={styles.footer__inner}>
            <div className={styles.footer__content}>
                <FooterColumn main>
                    <h2>
                        Interested?
                        <br />
                        <span>{ 'Let\'s talk!' }</span>
                    </h2>
                    <Button to="/contact" variant="outline">
                        Get in touch
                    </Button>
                </FooterColumn>
                <FooterColumn>
                    <Menu list={primaryMenu} />
                </FooterColumn>
                <FooterColumn>
                    <Menu list={secondaryMenu} />
                </FooterColumn>
            </div>
            <div className={styles.footer__copyright}>
                <div>
                    &copy; bohlbro.dk 2019
                </div>
                <div>
                    <Menu flow="horizontal" list={copyrightMenu} />
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
