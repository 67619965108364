/**
 * @file Containers / App / Routes
 */

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import routes from './routes';
import ScrollRestoration from './ScrollRestoration';
import NotFoundPage from '../../pages/NotFound';

const Router = () => (
    <div>
        <ScrollRestoration />
        <Switch>
            {routes.map(route => <Route key={route.path} {...route} />)}
            <Route component={NotFoundPage} />
        </Switch>
    </div>
);

export default Router;
