/**
 * Pages / About
 */

import React from 'react';

import Barley from '../../atoms/Barley';
import Wheat from '../../atoms/Wheat';
import Hero from '../../organisms/Hero';
import Block from '../../organisms/Block';
import PageTemplate from '../../templates/Page';

const AboutPage = () => (
    <PageTemplate label="home" title="Learn more about me">
        <Hero>
            <Hero.Header
                title="Learn more about me."
                subtitle="About me"
            />
            <Hero.Content>
                <Wheat>
                    { 'I\'m studying information- and communication technology (Software engineering) on Aarhus univserity. I\'m experienced in a wide range of web technologies, both frontend and backend. I also do software and embedded development.' }
                </Wheat>
            </Hero.Content>
        </Hero>
        <Block>
            <Block.Header
                title="Introduction"
            />
            <Block.Content>
                <h4>Where it all started</h4>
                <p>
                    { 'I\'ve been interested in computers ever since I can remember. I started to develop and interest for computer programming around the age of 11. I got my first laptop at age 13, then it escalated quickly. I started creating websites as school projects and helped friends and family with their websites.' }
                </p>
                <p>
                    I quickly became very fluent in HTML, CSS and JavaScript,
                    while also being quite proficient in PHP. After highschool,
                    I scored my first job as a frontend developer at a design
                    and marketing firm.
                </p>
            </Block.Content>
        </Block>
        <Block>
            <Block.Header
                title="Skills"
            />
            <Block.Content>
                <h4>Master</h4>
                <p>React, ES6 (JavaScript), SASS, HTML5, Git</p>
                <h4>Strong</h4>
                <p>
                    { 'Redux, NodeJS, Webpack, Rollup, Gulp, PHP, Wordpress, HubSpot, C, C++, C#, SQL' }
                </p>
                <h4>Knowledgable</h4>
                <p>MongoDB, Angular, Vue, ExpressJS</p>
            </Block.Content>
        </Block>
        <Block>
            <Block.Header
                title="Experience"
            />
            <Block.Content>
                <h4>Avidly Danmark</h4>
                <Barley>Sep/2016 &mdash; Aug/2017</Barley>
                <p>
                    Developed B2B websites solutions in HubSpot and wordpress,
                    as a part of an inbound marketing firm. As a part of a
                    design and development team, the main focus was on
                    growth-driven design and development.
                </p>
                <h4>Project Resurgence</h4>
                <Barley>Jan/2015 &mdash; Feb/2016</Barley>
                <p>
                    As a part of a game development studio with a successful,
                    I developed their website and setup their forums for
                    interacting with the players. I also created an email
                    system that integrated with the forums for sending out
                    newsletters.
                </p>
                <h4>TGV LAN</h4>
                <Barley>Mar/2014 &mdash; Jun/2016</Barley>
                <p>
                    Helped organise a large LAN party event for 250 - 300
                    students at HTX Viby (Highschool/Gymnasium). I was
                    responsible for acquiring sponsors for the game
                    tournaments, which lead to a prize-pool worth up to 70.000
                    DKK, for each event three times a year.
                </p>
            </Block.Content>
        </Block>
        <Block>
            <Block.Header
                title="Education"
            />
            <Block.Content>
                <h4>University</h4>
                <Barley>
                    Sep/2017
                    &mdash;
                    <i>present</i>
                </Barley>
                <p>
                    Developed B2B websites solutions in HubSpot and wordpress,
                    as a part of an inbound marketing firm. As a part of a
                    design and development team, the main focus was on
                    growth-driven design and development.
                </p>
                <h4>Technical Highschool (HTX)</h4>
                <Barley>Aug/2013 &mdash; Jun/2016</Barley>
                <p>
                    Graduated technical highschool with a major in Mathematics
                    and Physics, and with minor in robottechnology and programming.
                </p>
                <h4>Primary School</h4>
                <Barley>Mar/2014 &mdash; Jun/2016</Barley>
                <p>
                    Graduated primary school, grade 0 through 9 at Malling Skole in my hometown.
                </p>
            </Block.Content>
        </Block>
    </PageTemplate>
);

export default AboutPage;
