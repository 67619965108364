import React from 'react';
import { Link } from 'react-router-dom';

import styles from './logo.scss';

const Logo = () => (
    <div className={styles.logo}>
        <Link
            to="/"
        >
            <span>bohlbro.</span>
        </Link>
    </div>
);

export default Logo;
