import React from 'react';
import PropTypes from 'prop-types';

import HeroHeader from './HeroHeader';
import HeroContent from './HeroContent';
import HeroImage from './HeroImage';

import styles from './hero.scss';

const Hero = ({
    children,
    front,
}) => (
    <section className={styles.hero}>
        {front ? (
            <div className={styles.hero__bg}>
                <div className={styles.bg__item} />
                <div className={styles.bg__item} />
                <div className={styles.bg__item} />
                <div className={styles.bg__item} />
            </div>
        ) : null}
        <div className={styles.hero__inner}>
            {children}
        </div>
    </section>
);
Hero.propTypes = {
    // The hero content
    children: PropTypes.node.isRequired,

    // If the hero should display boxes as front
    front: PropTypes.bool,
};
Hero.defaultProps = {
    front: false,
};

// Static HeroHeader component
Hero.Header = HeroHeader;

// Static HeroContent component
Hero.Content = HeroContent;

// Static HeroImage component
Hero.Image = HeroImage;

export default Hero;
