import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PillarsColumn from './PillarsColumn';

import styles from './pillars.scss';

const Pillars = ({
    title,
    subtitle,
    children,
    dark,
}) => {
    const className = classNames(
        styles.pillars,
        {
            [styles['pillars--dark']]: dark,
        }
    );

    return (
        <section className={className}>
            <div className={styles.pillars__inner}>
                {title ? (
                    <div className={styles.pillars__header}>
                        <h2>{title}</h2>
                        {subtitle ? <p>{subtitle}</p> : null}
                    </div>
                ) : null}
                <div className={styles.pillars__content}>
                    {children}
                </div>
            </div>
        </section>
    );
};
Pillars.Column = PillarsColumn;
Pillars.propTypes = {
    // The header title
    title: PropTypes.string,

    // The header subtitle
    subtitle: PropTypes.string,

    // If the background should have a fade dark
    dark: PropTypes.bool,

    // The pillars content, consiting of single pillars
    children: PropTypes.node.isRequired,
};
Pillars.defaultProps = {
    title: null,
    subtitle: null,
    dark: false,
};

export default Pillars;
