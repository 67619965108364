/**
 * Pages / Home
 */

import React from 'react';

import Block from '../../organisms/Block';
import PageTemplate from '../../templates/Page';

const ContactPage = () => (
    <PageTemplate label="home" title="Contact. Get in touch.">
        <Block vertical="top">
            <Block.Column>
                <h1>Get in touch.</h1>
            </Block.Column>
            <Block.Column>
                <p>
                    { 'If you\'re interested in talking, have questions or just want to say hi, then feel free to contact me at anytime. I will try to get back to you as soon as possible!' }
                </p>
                <p>
                    <span className="barley">Email</span>
                    <br />
                    <a href="mailto:hello@bohlbro.dk">hello@bohlbro.dk</a>
                </p>
                <p>
                    <span className="barley">Telephone</span>
                    <br />
                    +45 30 52 06 97
                </p>
            </Block.Column>
        </Block>
    </PageTemplate>
);

export default ContactPage;
