import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BlockHeader from './BlockHeader';
import BlockColumn from './BlockColumn';
import BlockContent from './BlockContent';

import styles from './block.scss';

const Block = ({
    children,
    align,
    vertical,
    dark,
}) => {
    const className = classNames(
        styles.block,
        {
            [styles[`block--${align}`]]: align !== 'center',
            [styles[`block--${vertical}`]]: vertical !== 'center',
            [styles['block--dark']]: dark,
        }
    );

    return (
        <section className={className}>
            <div className={styles.block__inner}>
                {children}
            </div>
        </section>
    );
};
Block.propTypes = {
    // The block content. Use the attached static components.
    children: PropTypes.node.isRequired,

    // Horizontal alignment
    align: PropTypes.oneOf([
        'center', // Default
        'left',
        'right',
    ]),

    // Vertical alignment
    vertical: PropTypes.oneOf([
        'center', // Default
        'top',
        'bottom',
    ]),

    // If the background should have a fade dark
    dark: PropTypes.bool,
};
Block.defaultProps = {
    align: 'center',
    vertical: 'center',
    dark: false,
};

// Static sub components
Block.Header = BlockHeader;
Block.Content = BlockContent;
Block.Column = BlockColumn;

export default Block;
