import React from 'react';
import PropTypes from 'prop-types';

import styles from './hero.scss';

const HeroImage = ({
    img,
}) => (
    <div className={styles.hero__image}>
        <img src={img} alt="" />
    </div>
);
HeroImage.propTypes = {
    // The image source path for display the content as an image
    img: PropTypes.string.isRequired,
};

export default HeroImage;
