import React from 'react';
import PropTypes from 'prop-types';

import styles from './block.scss';

/**
 * Used for displayed content across the block section
 */
const BlockContent = ({
    children,
}) => (
    <div className={styles.block__content}>
        {children}
    </div>
);
BlockContent.propTypes = {
    // The component content
    children: PropTypes.node.isRequired,
};

export default BlockContent;
