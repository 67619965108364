import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './footer.scss';

const FooterColumn = ({
    children,
    main,
}) => (
    <div
        className={classNames(
            styles.footer__column,
            { [styles['footer__column--main']]: main }
        )}
    >
        {children}
    </div>
);
FooterColumn.propTypes = {
    // The footer column content
    children: PropTypes.node.isRequired,

    // Defines the main column, getting the most width
    main: PropTypes.bool,
};
FooterColumn.defaultProps = {
    main: false,
};

export default FooterColumn;
