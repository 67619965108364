import React from 'react';
import PropTypes from 'prop-types';

import styles from './hero.scss';

const HeroContent = ({
    children,
}) => (
    <div className={styles.hero__content}>
        {children}
    </div>
);
HeroContent.propTypes = {
    // The hero content
    children: PropTypes.node.isRequired,
};

export default HeroContent;
