/**
 * @file    Molecules / Menu
 *
 * Menu component for listing links as a menu, either vertical or
 * horizontal.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MenuItem from './MenuItem';

import styles from './menu.scss';

const Menu = ({
    list,
    flow,
    primary,
    onItemClick,
}) => {
    const className = classNames(
        styles.menu,
        {
            [styles['menu--horizontal']]: flow !== 'vertical',
            [styles['menu--primary']]: primary,
        },
    );

    return (
        <ul className={className}>
            {list.map(item => (
                <MenuItem
                    key={item.to || item.href}
                    to={item.to || null}
                    href={item.href || null}
                    onClick={e => onItemClick(e)}
                >
                    {item.label}
                </MenuItem>
            ))}
        </ul>
    );
};
Menu.propTypes = {
    // A array list of the menu items
    list: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string,
            href: PropTypes.string,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,

    // The flow of the menu
    flow: PropTypes.oneOf([
        'vertical',
        'horizontal',
    ]),

    // Used to set the primary menu type [unique]
    primary: PropTypes.bool,

    onItemClick: PropTypes.func,
};
Menu.defaultProps = {
    flow: 'vertical',
    primary: false,
    onItemClick: () => {},
};

export default Menu;
