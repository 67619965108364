import React from 'react';

import Router from './utils/Router';

// Import global scss
import 'normalize.css/normalize.css';
import './app.scss';

const App = () => (
    <div className="site">
        <Router />
    </div>
);

export default App;
