import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './header.scss';

const Burgermenu = ({
    collapsed,
    onClick,
}) => (
    <div
        className={classNames(styles.burgermenu, {
            [styles['burgermenu--toggled']]: collapsed,
        })}
    >
        <button
            type="button"
            onClick={e => onClick(e)}
        >
            <span />
        </button>
    </div>
);
Burgermenu.propTypes = {
    // Wether to show the menu as 'close'
    collapsed: PropTypes.bool.isRequired,

    // The on click callback function
    onClick: PropTypes.func.isRequired,
};

export default Burgermenu;
