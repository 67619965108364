/**
 * @file    Organisms / Header
 *
 * The sites main header, displayed on most pages.
 */

import React from 'react';

import Logo from '../../atoms/Logo';
import Navigation from './Navigation';

import styles from './header.scss';

const Header = () => (
    <header className={styles.header}>
        <div className={styles.header__inner}>
            <Logo />
            <Navigation />
        </div>
    </header>
);

export default Header;
