/**
 * Pages / Home
 */

import React from 'react';

import Wheat from '../../atoms/Wheat';
import Button from '../../atoms/Button';
import Hero from '../../organisms/Hero';
import Block from '../../organisms/Block';
import Pillars from '../../organisms/Pillars';
import PageTemplate from '../../templates/Page';

import CoverImage from './vignette-cover.jpg';

const HomePage = () => (
    <PageTemplate label="home" title="Home">
        <Hero>
            <Hero.Header
                subtitle="Hello. I'm Christian H. Bohlbro"
                title="Software engineering student and freelance developer,
                with a passion for web and design."
            />
            <Hero.Image
                img={CoverImage}
            />
        </Hero>
        <Block vertical="top">
            <Block.Column>
                <h2>
                    A Curious
                    <br />
                    Mind
                </h2>
                <Button
                    variant="outline"
                    to="/about"
                >
                    Learn more
                </Button>
            </Block.Column>
            <Block.Column>
                <Wheat>
                    { 'I love learning! I\'m selftaught in most of the web-related development I know, and getting better by the day.' }
                </Wheat>
            </Block.Column>
        </Block>
        <Pillars
            title="Focus areas"
            subtitle="These are my main focus areas"
            dark
        >
            <Pillars.Column>
                <h3>Software</h3>
                <p>
                    From studying software engineering, I can create reliable
                    software with focus on scalablity and performance.
                </p>
            </Pillars.Column>
            <Pillars.Column>
                <h3>Web</h3>
                <p>
                    Developing interactive and functional websites. Everything
                    from reactive single-page applications, to beautiful
                    storytelling websites.
                </p>
            </Pillars.Column>
            <Pillars.Column>
                <h3>Design</h3>
                <p>
                    Creating clean and simple UI & UX, with focus on brand and
                    identity to drive growth
                </p>
            </Pillars.Column>
        </Pillars>
    </PageTemplate>
);

export default HomePage;
