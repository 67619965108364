/**
 * @file    Organisms / Header / Navigation
 *
 * The headers navigation. Displays a Burgermenu that opens the navigation
 * window via the header
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import Menu from '../../molecules/Menu';
import Burgermenu from './Burgermenu';

import styles from './header.scss';

const primaryItems = [
    {
        to: '/',
        label: 'Home',
    },
    /*
    {
        to: '/work',
        label: 'Work',
    },
    */
    {
        to: '/about',
        label: 'About',
    },
    {
        to: '/contact',
        label: 'Contact',
    },
];

const secondaryItems = [
    /*
    {
        href: 'https://play.bohlbro.dk',
        label: 'playground',
    },
    */
    {
        href: 'https://dribbble.com/Phoenix1355',
        label: 'dribbble',
    },
    {
        href: 'https://codepen.io/Phoenix1355/',
        label: 'codepen',
    },
    {
        href: 'https://github.com/Phoenix1355',
        label: 'github',
    },
    {
        href: 'https://bohlbro.dk/resume.pdf',
        label: 'resume',
    },
    /*
    {
        to: '/blog',
        label: 'blog',
    },
    */
];

@withRouter
class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            loaded: false,
        };
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({
            loaded: true,
        }), 300);
    }

    componentWillUnmount = () => {
        // Making sure that the class isn't enabled when the component is
        // unmounted
        this.setState({
            visible: false,
        });

        document.body.classList.remove('js-menu-toggle');
    }

    componentDidUpdate = () => {
        const {
            visible,
        } = this.state;

        // If it wasen't visible before
        if (visible) {
            window.scrollTo(0, 0);
            document.body.classList.add('js-menu-toggle');
        } else {
            document.body.classList.remove('js-menu-toggle');
        }
    }

    handleBurgerClick = () => {
        this.setState(prevState => ({
            visible: !prevState.visible,
        }));
    }

    handleItemClick = () => {
        this.setState({
            visible: false,
        });
    }

    render() {
        const {
            visible,
            loaded,
        } = this.state;

        return (
            <nav className={styles.navigation}>
                <Burgermenu
                    onClick={this.handleBurgerClick}
                    collapsed={visible}
                />
                <div
                    className={classNames(
                        styles.overlay,
                        {
                            [styles['overlay--loaded']]: loaded,
                            [styles['overlay--visible']]: visible,
                        }
                    )}
                >
                    <div className={styles.overlay__inner}>
                        <div className={styles.overlay__secondary}>
                            <Menu
                                list={secondaryItems}
                                onItemClick={this.handleItemClick}
                            />
                        </div>
                        <div className={styles.overlay__primary}>
                            <Menu
                                primary
                                list={primaryItems}
                                onItemClick={this.handleItemClick}
                            />
                        </div>
                        <div className={styles.overlay__content}>
                            <div className={styles.overlay__group}>
                                <small>Say hello</small>
                                <a href="mailto:">hello@bohlbro.dk</a>
                            </div>
                            <div className={styles.overlay__group}>
                                <small>Follow me</small>
                                <a href="https://www.facebook.com/Phoenix1355">Fb</a>
                                <a href="https://www.linkedin.com/in/chrhb">In</a>
                                <a href="https://twitter.com/Phoenix1355">Tw</a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navigation;
