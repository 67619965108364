import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';

import styles from './menu.scss';

const MenuItem = ({
    children,
    seperator,
    onClick,
    to,
    href,
    location,
}) => {
    if (seperator) {
        return (<li className={styles['menu__item--seperator']} />);
    }

    const className = classNames(
        styles.menu__item,
        {
            [styles['menu__item--current']]: location.pathname === to || location.pathname === href,
        },
    );

    return (
        <li className={className}>
            {to ? (
                <Link to={to || '/'} onClick={onClick}>
                    <span>{children}</span>
                </Link>
            ) : null}
            {href ? (
                <a href={href}>
                    <span>{children}</span>
                </a>
            ) : null}
        </li>
    );
};
MenuItem.propTypes = {
    // The label text displayed in the item
    children: PropTypes.string.isRequired,

    // The linked url
    to: PropTypes.string,

    // Used for external linked urls
    href: PropTypes.string,

    // The onClick callback. Only applicable if the menu item is relative
    onClick: PropTypes.func,

    // Will print the item as a seperator. Overrides 'to' and 'onClick'.
    seperator: PropTypes.bool,

    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};
MenuItem.defaultProps = {
    to: null,
    href: null,
    onClick: () => {},
    seperator: false,
};

export default withRouter(MenuItem);
