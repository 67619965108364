import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Icon from '../Icon';

import styles from './button.scss';

const Button = ({
    children,
    className: classNameProp,
    color,
    disabled,
    size,
    variant,
    align,
    icon,
    ...other
}) => {
    const className = classNames(
        styles.button,
        {
            [styles[`button--${size}`]]: size !== 'md',
            [styles[`button--${color}`]]: color !== 'default',
            [styles[`button--${variant}`]]: variant !== 'default',
            [styles[`button--${align}`]]: align !== 'left',
            [styles['button--disabled']]: disabled,
        },
        classNameProp,
    );

    const Component = other.to ? Link : 'button';

    return (
        <Component
            className={className}
            disabled={disabled}
            {...other}
        >
            {icon ? <Icon type={icon} /> : null}
            <span className={styles.button__label}>{children}</span>
        </Component>
    );
};
Button.propTypes = {
    // The content of the button
    children: PropTypes.node.isRequired,

    // The custom classname added to the button
    className: PropTypes.string, // eslint-disable-line

    // The color of the button. Supports all colors defined in the scss
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),

    // If the button should be disabled
    disabled: PropTypes.bool,

    // The url to link when the button is clicked
    to: PropTypes.string,

    // The size of the button
    size: PropTypes.oneOf(['sm', 'md', 'lg']),

    // The variant of the button
    variant: PropTypes.oneOf(['default', 'outline', 'simple']),

    // Alignment of the button
    align: PropTypes.oneOf(['left', 'center', 'right']),

    // An icon to be displayed before the text. The validation of this prop
    // is made by the icon component itself, and not here since it would be
    // validating twice with no improved effect.
    icon: PropTypes.string,
};
Button.defaultProps = {
    color: 'default',
    disabled: false,
    to: null,
    size: 'md',
    variant: 'default',
    align: 'left',
    icon: null,
};

export default Button;
