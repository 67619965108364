import React from 'react';
import PropTypes from 'prop-types';

import styles from './hero.scss';

import Wheat from '../../atoms/Wheat';

const HeroHeader = ({
    title,
    subtitle,
}) => (
    <div className={styles.hero__header}>
        {subtitle
            ? (<Wheat id="page-subtitle">{subtitle}</Wheat>)
            : null}
        {title
            ? (<h1 id="page-title">{title}</h1>)
            : null}
    </div>
);
HeroHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};
HeroHeader.defaultProps = {
    title: null,
    subtitle: null,
};

export default HeroHeader;
