import React from 'react';
import PropTypes from 'prop-types';

import styles from './barley.scss';

const Barley = ({
    children,
}) => (
    <small className={styles.barley}>
        {children}
    </small>
);
Barley.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Barley;
