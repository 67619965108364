import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './fonts/icons.font';

const Icon = ({
    type,
}) => (
    <span
        className={classNames('icon', `icon-${type}`)}
    />
);
Icon.propTypes = {
    // The icon type
    type: PropTypes.oneOf([
        'arrow-left',
        'caret-left',
        'caret-right',
    ]).isRequired,
};

export default Icon;
