import React from 'react';
import PropTypes from 'prop-types';

import styles from './wheat.scss';

const Wheat = ({
    children,
}) => (
    <p className={styles.wheat}>
        {children}
    </p>
);
Wheat.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Wheat;
