import React from 'react';
import PropTypes from 'prop-types';

import styles from './block.scss';

/**
 * Used for displayed content across the block section
 */
const BlockHeader = ({
    title,
    subtitle,
}) => (
    <div className={styles.block__header}>
        {title
            ? (<h2>{title}</h2>)
            : null}
        {subtitle
            ? (<p>{subtitle}</p>)
            : null}
    </div>
);
BlockHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};
BlockHeader.defaultProps = {
    title: null,
    subtitle: null,
};

export default BlockHeader;
