import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollRestoration = ({ location }) => {
    useEffect(() => {
        document.body.classList.remove('js-menu-toggle');
        window.scrollTo(0, 0);
    }, [location]);

    return null;
};

export default withRouter(ScrollRestoration);
