/**
 * @file    utils / Routes.js
 *
 * Manages all the route configurations.
 * Used by the server and client
 */

import HomePage from '../../pages/Home';
// import WorkPage from '../../pages/Work';
import AboutPage from '../../pages/About';
import ContactPage from '../../pages/Contact';
import PrivacyPage from '../../pages/Privacy';

const routes = [
    {
        path: '/',
        exact: true,
        component: HomePage,
    },
    /*
    {
        path: '/work',
        exact: true,
        component: WorkPage,
    },
    */
    {
        path: '/about',
        component: AboutPage,
    },
    {
        path: '/contact',
        component: ContactPage,
    },
    {
        path: '/privacy',
        component: PrivacyPage,
    },
];

export default routes;
