/**
 * Icon font
 */

/* eslint-disable */

module.exports = {
    "files": [
        '../icons/*.svg',
    ],
    'fontName': 'icon-font',
    'classPrefix': 'icon-',
    'baseSelector': '.icon',
    'types': [
        'ttf',
        'eot',
        'woff',
        'woff2',
        'svg',
    ],
    'fileName': '[fontname].[ext]',
    'fixedWidth': true,
};

/* eslint no-use-before-define: 2 */
