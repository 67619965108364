/**
 * @file    index.js
 *
 * The client-side index file for rendering the main App
 *
 * @author  Christian H. Bohlbro
 * @version 0.2.1
 */

import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

/**
 * The main DOM element
 * @type {Node}
 */
const MOUNT_NODE = document.querySelector('#root');

/**
 * Main render funtion for rendering the app to the HTML DOM
 */
ReactDOM.render(
    <HttpsRedirect>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </HttpsRedirect>,
    MOUNT_NODE,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
